@font-face {
    font-family: "Copyright Klim Type Foundry";
    src: url("./copyright/copyrightklim.eot"); /* IE9*/
    src: url("./copyright/copyrightklim.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./copyright/copyrightklim.woff2") format("woff2"), /* chrome firefox */
    url("./copyright/copyrightklim.woff") format("woff"), /* chrome firefox */
    url("./copyright/copyrightklim.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
    url("./copyright/copyrightklim.svg# Copyright Klim Type Foundry") format("svg"); /* iOS 4.1- */
}

@font-face {
    font-family: 'Museo Sans';
    src: url('./museosans/MuseoSans_500.otf') format('embedded-opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans';
    src: url('./museosans/MuseoSans-100.otf') format('embedded-opentype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans';
    src: url('./museosans/MuseoSans-300.otf') format('embedded-opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans';
    src: url('./museosans/MuseoSans_500_Italic.otf') format('embedded-opentype');
    font-weight: 500;
    font-style: italic;
}
